import React from "react";
import HeroBox from "../HeroBox";
import { heroImages } from "../../resources/images";
import Spinner from "react-bootstrap/Spinner";
// import Reaptcha from 'reaptcha';
import "./styles.css";

class PilotRegistration extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      first_name: "",
      last_name: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      mobile: "",
      email: "",
      pilot_certificate_number: "",
      pilot_rating: "Private",
      total_hours_pic: "",
      insurance_carrier: "",
      bfa_hacd_number: "",
      most_recent_flight_review: "",
      registration_number: "",
      manufacturer: "",
      model: "",
      year_of_manufacture: "",
      registered_owner: "",
      balloon_name: "",
      balloon_description: "",
      most_recent_annual_inspection: "",
      hotel: "Wingate by Wyndham",
      pilot_room_check_in: "",
      pilot_room_check_out: "",
      pilot_room_preference: "King Bed",
      local_crew: "NO",
      number_crew_rooms: "",
      crew_room_check_in: "",
      crew_room_check_out: "",
      crew_room_preference: "",
      friday_morning_event: "",
      meal_party_size: "",
      glow_participant: "",
      awards_banquet_seats: "",
      pilot_shirt_size: "",
      agree_to_terms: false,
      comments: "",
      balloon_type: "sport",
      // airworthiness_cert: {
      //     data: null,
      //     mimetype: null,
      //     filename: null
      // },
      // aircraft_registration: {
      //     data: null,
      //     mimetype: null,
      //     filename: null
      // },
      // balloon_image: {
      //     data: null,
      //     mimetype: null,
      //     filename: null
      // },
      loading: false,
      // captchaState: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.onFileChange = this.onFileChange.bind(this);
    // this.onCaptchaChange = this.onCaptchaChange(this);
  }

  // onCaptchaChange = (e) => {
  //     console.log(this.state.captchaState);
  //     this.setState({captchaState: true})
  // }

  // onFileChange = (e, field) => {
  //     var file = e.target.files[0];
  //     var fr = new FileReader();
  //     fr.fileName= file.name;
  //     fr.onload = (e) => {
  //         this.setState({
  //             [field]: {
  //                 data: e.target.result.replace(/^.*,/, ''),
  //                 mimetype: e.target.result.match(/^.*(?=;)/)[0],
  //                 filename: e.target.fileName
  //             }
  //         })
  //     }
  //     fr.readAsDataURL(file);
  // }

  handleSubmit = (e) => {
    e.preventDefault();
    // if (!this.state.captchaState) {
    //     return false;
    // }
    /** SEND TO GOOGLE SHEETS */
    this.setState({ loading: true });
    var sheetsDataToSend = new FormData();
    sheetsDataToSend.append("first_name", this.state.first_name);
    sheetsDataToSend.append("last_name", this.state.last_name);
    sheetsDataToSend.append(
      "address",
      this.state.address1 + " " + this.state.address2
    );
    sheetsDataToSend.append("city", this.state.city);
    sheetsDataToSend.append("state", this.state.state);
    sheetsDataToSend.append("zip", this.state.zip);
    sheetsDataToSend.append("country", this.state.country);
    sheetsDataToSend.append("mobile", this.state.mobile);
    sheetsDataToSend.append("email", this.state.email);
    sheetsDataToSend.append(
      "pilot_certificate_number",
      this.state.pilot_certificate_number
    );
    sheetsDataToSend.append("pilot_rating", this.state.pilot_rating);
    sheetsDataToSend.append("total_hours_pic", this.state.total_hours_pic);
    sheetsDataToSend.append("insurance_carrier", this.state.insurance_carrier);
    sheetsDataToSend.append("bfa_hacd_number", this.state.bfa_hacd_number);
    sheetsDataToSend.append(
      "most_recent_flight_review",
      this.state.most_recent_flight_review
    );
    sheetsDataToSend.append(
      "registration_number",
      this.state.registration_number
    );
    sheetsDataToSend.append("manufacturer", this.state.manufacturer);
    sheetsDataToSend.append("model", this.state.model);
    sheetsDataToSend.append(
      "year_of_manufacture",
      this.state.year_of_manufacture
    );
    sheetsDataToSend.append("registered_owner", this.state.registered_owner);
    sheetsDataToSend.append("balloon_name", this.state.balloon_name);
    sheetsDataToSend.append(
      "balloon_description",
      this.state.balloon_description
    );
    sheetsDataToSend.append(
      "most_recent_annual_inspection",
      this.state.most_recent_annual_inspection
    );
    sheetsDataToSend.append("hotel", this.state.hotel);
    sheetsDataToSend.append(
      "pilot_room_check_in",
      this.state.pilot_room_check_in
    );
    sheetsDataToSend.append(
      "pilot_room_check_out",
      this.state.pilot_room_check_out
    );
    sheetsDataToSend.append(
      "pilot_room_preference",
      this.state.pilot_room_preference
    );
    sheetsDataToSend.append("local_crew", this.state.local_crew);
    sheetsDataToSend.append("number_crew_rooms", this.state.number_crew_rooms);
    sheetsDataToSend.append(
      "crew_room_check_in",
      this.state.crew_room_check_in
    );
    sheetsDataToSend.append(
      "crew_room_check_out",
      this.state.crew_room_check_out
    );
    sheetsDataToSend.append(
      "crew_room_preference",
      this.state.crew_room_preference
    );
    sheetsDataToSend.append(
      "friday_morning_event",
      this.state.friday_morning_event
    );
    sheetsDataToSend.append("meal_party_size", this.state.meal_party_size);
    sheetsDataToSend.append("glow_participant", this.state.glow_participant);
    sheetsDataToSend.append(
      "awards_banquet_seats",
      this.state.awards_banquet_seats
    );
    sheetsDataToSend.append("pilot_shirt_size", this.state.pilot_shirt_size);
    sheetsDataToSend.append("agree_to_terms", this.state.agree_to_terms);
    sheetsDataToSend.append("comments", this.state.comments);
    sheetsDataToSend.append("balloon_type", this.state.balloon_type);
    const sheetsURL =
      "https://script.google.com/macros/s/AKfycbxiTlQ3yv5LekJzjzE1kA1mtCntFWJxyxTpHS1OgedIsMjYrZQ2/exec";
    fetch(sheetsURL, {
      method: "POST",
      body: sheetsDataToSend,
    }).then((response) => {
      this.setState({ loading: false });
      if (this.state.balloon_type === "sport") {
        window.location.href = "https://www.paypal.com/paypalme/2019ABA/50";
      } else if (this.state.balloon_type === "corporate") {
        window.location.href = "https://www.paypal.com/paypalme/2019ABA/650";
      } else if (this.state.balloon_type === "specialShape") {
        window.location.href = "https://www.angolaballoonsaloft.com";
      }
    });
    // .catch((error) => console.error(error.message));

    /** SEND TO GOOGLE DRIVE */
    // const fileNames = ['airworthiness_cert', 'aircraft_registration', 'balloon_image'];
    // var driveDataToSend = new FormData();
    // driveDataToSend.append('foldername', (this.state.balloon_name + "_" + this.state.first_name + "_" + this.state.last_name));
    // fileNames.forEach(fileName => {
    //     const file = this.state.[fileName];
    //     const fr = new FileReader();
    //     fr.fileName= file.name;
    //     driveDataToSend.append(fileName + '_data', file.data);
    //     driveDataToSend.append(fileName + '_mimetype', file.mimetype);
    //     driveDataToSend.append(fileName + '_filename', file.filename);
    // });
    //     const fileUploadURL ="https://script.google.com/macros/s/AKfycbwHUaJazxmKxp9Neqv2vzR5BXX29VS3cT8Xo6f73ZUuOPI_8DVL/exec";
    //     const sendForm = async () => {
    //         await fetch(fileUploadURL, {
    //             method: "POST",
    //             mode: 'no-cors',
    //             body: driveDataToSend
    //         })
    //         .then((response) => {
    //             this.setState({loading: false});
    //             if (this.state.balloon_type === 'sport') {
    //                 window.location.href = 'https://www.paypal.com/paypalme/2019ABA/50';
    //             } else if (this.state.balloon_type === 'corporate') {
    //                 window.location.href = 'https://www.paypal.com/paypalme/2019ABA/650';
    //             }
    //         })
    //         // .catch((error) => console.error(error.message));
    //     }
    //     sendForm();
  };

  render() {
    const executeScroll = () => this.myRef.current.scrollIntoView();
    return (
      <div>
        <HeroBox
          bgImage={heroImages.pilot_registration}
          title={"2025 Pilot Registration"}
          content={""}
          executeScroll={executeScroll}
          buttonText={"Register Below"}
        />

        <div className="pilot-registration-wrapper" ref={this.myRef}>
          <form name="pilot_reg_form" onSubmit={this.handleSubmit}>
            <section>
              <h1>Pilot Information</h1>
              <label htmlFor="first_name">First Name</label>
              <input
                required
                type="text"
                name="first_name"
                placeholder="First Name"
                onChange={(e) => {
                  this.setState({ first_name: e.target.value });
                }}
              />
              <label htmlFor="last_name">Last Name</label>
              <input
                required
                type="text"
                name="last_name"
                placeholder="Last Name"
                onChange={(e) => {
                  this.setState({ last_name: e.target.value });
                }}
              />
              <label htmlFor="address1">Address Line 1</label>
              <input
                required
                type="text"
                name="address1"
                placeholder="Address Line 1"
                onChange={(e) => {
                  this.setState({ address1: e.target.value });
                }}
              />
              <label htmlFor="address2">Address Line 2</label>
              <input
                type="text"
                name="address2"
                placeholder="Address Line 2"
                onChange={(e) => {
                  this.setState({ address2: e.target.value });
                }}
              />
              <label htmlFor="city">City</label>
              <input
                required
                type="text"
                name="city"
                placeholder="City"
                onChange={(e) => {
                  this.setState({ city: e.target.value });
                }}
              />
              <label htmlFor="state">State</label>
              <input
                required
                type="text"
                name="state"
                placeholder="State"
                onChange={(e) => {
                  this.setState({ state: e.target.value });
                }}
              />
              <label htmlFor="zip">Zip</label>
              <input
                required
                type="text"
                name="zip"
                placeholder="zip"
                onChange={(e) => {
                  this.setState({ zip: e.target.value });
                }}
              />
              <label htmlFor="country">Country</label>
              <input
                required
                type="text"
                name="country"
                placeholder="Country"
                onChange={(e) => {
                  this.setState({ country: e.target.value });
                }}
              />
              <label htmlFor="mobile">Mobile Number</label>
              <input
                required
                type="text"
                name="mobile"
                placeholder="Mobile Number"
                onChange={(e) => {
                  this.setState({ mobile: e.target.value });
                }}
              />
              <label htmlFor="email">Email</label>
              <input
                required
                type="text"
                name="email"
                placeholder="Email"
                onChange={(e) => {
                  this.setState({ email: e.target.value });
                }}
              />
              <label htmlFor="pilot_certificate_number">
                Pilot Certificate Number
              </label>
              <input
                required
                type="text"
                name="pilot_certificate_number"
                placeholder="Pilot Certificate"
                onChange={(e) => {
                  this.setState({ pilot_certificate_number: e.target.value });
                }}
              />

              <label htmlFor="pilot_rating">Pilot Rating</label>
              <select
                value={this.state.pilot_rating}
                onChange={(e) => {
                  this.setState({ pilot_rating: e.target.value });
                }}
              >
                <option value="Private">Private</option>
                <option value="Commercial">Commercial</option>
              </select>

              <label htmlFor="total_hours_pic">Total Hours PIC</label>
              <input
                required
                type="text"
                name="total_hours_pic"
                placeholder="Total Hours PIC"
                onChange={(e) => {
                  this.setState({ total_hours_pic: e.target.value });
                }}
              />
              <label htmlFor="insurance_carrier">Insurance Carrier</label>
              <input
                required
                type="text"
                name="insurance_carrier"
                placeholder="Insurance Carrier"
                onChange={(e) => {
                  this.setState({ insurance_carrier: e.target.value });
                }}
              />
              <label htmlFor="bfa_hacd_number">BFA HACD Number</label>
              <input
                required
                type="text"
                name="bfa_hacd_number"
                placeholder="BFA HACD Number"
                onChange={(e) => {
                  this.setState({ bfa_hacd_number: e.target.value });
                }}
              />

              <label htmlFor="most_recent_flight_review">
                Most Recent Flight Review
              </label>
              <input
                required
                type="date"
                name="most_recent_flight_review"
                placeholder="Most recent flight review"
                onChange={(e) => {
                  this.setState({ most_recent_flight_review: e.target.value });
                }}
              />
            </section>

            <section>
              <h1>Balloon Information</h1>
              <label htmlFor="registration_number">
                Registration Number (N#)
              </label>
              <input
                required
                type="text"
                name="registration_number"
                placeholder="Registration Number"
                onChange={(e) => {
                  this.setState({ registration_number: e.target.value });
                }}
              />
              <label htmlFor="manufacturer">Manufacturer</label>
              <input
                required
                type="text"
                name="manufacturer"
                placeholder="Manufacturer"
                onChange={(e) => {
                  this.setState({ manufacturer: e.target.value });
                }}
              />
              <label htmlFor="model">Model</label>
              <input
                required
                type="text"
                name="model"
                placeholder="Model"
                onChange={(e) => {
                  this.setState({ model: e.target.value });
                }}
              />
              <label htmlFor="year_of_manufacture">Year of Manufacture</label>
              <input
                required
                type="text"
                name="year_of_manufacture"
                placeholder="Year of Manufacture"
                onChange={(e) => {
                  this.setState({ year_of_manufacture: e.target.value });
                }}
              />
              <label htmlFor="registered_owner">Registered Owner</label>
              <input
                required
                type="text"
                name="registered_owner"
                placeholder="Registered Owner"
                onChange={(e) => {
                  this.setState({ registered_owner: e.target.value });
                }}
              />
              <label htmlFor="balloon_name">Balloon Name</label>
              <input
                required
                type="text"
                name="balloon_name"
                placeholder="Balloon Name"
                onChange={(e) => {
                  this.setState({ balloon_name: e.target.value });
                }}
              />
              <label htmlFor="balloon_description">Balloon Description</label>
              <input
                required
                type="text"
                name="balloon_description"
                placeholder="Balloon Description"
                onChange={(e) => {
                  this.setState({ balloon_description: e.target.value });
                }}
              />
              <label htmlFor="most_recent_annual_inspection">
                Most Recent Annual Inspection
              </label>
              <input
                required
                type="date"
                name="most_recent_annual_inspection"
                placeholder="Most recent annual inspection"
                onChange={(e) => {
                  this.setState({
                    most_recent_annual_inspection: e.target.value,
                  });
                }}
              />

              {/* <label htmlFor="airworthiness_cert">PDF Airworthiness Cert</label>
                            <input required type="file" name="airworthiness_cert" onChange={(e) => {this.onFileChange(e, 'airworthiness_cert')}}/>
                            <label htmlFor="aircraft_registration">PDF Aircraft Registration</label>
                            <input required type="file" name="aircraft_registration" onChange={(e) => {this.onFileChange(e, 'aircraft_registration')}}/>
                            <label htmlFor="balloon_image">Balloon Image</label>
                            <input required type="file" name="balloon_image" onChange={(e) => {this.onFileChange(e, 'balloon_image')}}/> */}
            </section>

            <section className="hotel-info">
              <h1>Hotel Information</h1>
              <p>
                The event has reserved rooms in the Wingate by Wyndham hotel.
              </p>
              <p>Wingate by Wyndham</p>
              <p>3081 Enterprise Drive</p>
              <p>Angola, IN 46703</p>
              <p>(260) 739-1752</p>
              <p>Pets are not permitted at this hotel.</p>
              <label htmlFor="hotel">Hotel</label>
              <select
                name="hotel"
                value={this.state.hotel}
                onChange={(e) => {
                  this.setState({ hotel: e.target.value });
                }}
              >
                <option value="Wingate by Wyndham">Wingate by Wyndham</option>
              </select>

              <label htmlFor="pilot_room_check_in">
                Pilot Room Check-in Date
              </label>
              <input
                required
                type="date"
                name="pilot_room_check_in"
                placeholder="Pilot Room Check-in"
                onChange={(e) => {
                  this.setState({ pilot_room_check_in: e.target.value });
                }}
              />
              <label htmlFor="pilot_room_check_out">
                Pilot Room Check-out Date
              </label>
              <input
                required
                type="date"
                name="pilot_room_check_out"
                placeholder="Pilot Room Check-out"
                onChange={(e) => {
                  this.setState({ pilot_room_check_out: e.target.value });
                }}
              />

              <label htmlFor="pilot_room_preference">
                Pilot Room Preference
              </label>
              <select
                name="pilot_room_preference"
                value={this.state.pilot_room_preference}
                onChange={(e) => {
                  this.setState({ pilot_room_preference: e.target.value });
                }}
              >
                <option
                  value=""
                  disabled
                  selected
                  className="select-placeholder"
                >
                  Select option..
                </option>
                <option value="King Bed">King Bed</option>
                <option value="Two Queens">Two Queens</option>
              </select>

              <label htmlFor="number_crew_rooms">
                Number of Crew Rooms (Not Paid For by Event)
              </label>
              <input
                type="text"
                name="number_crew_rooms"
                placeholder="Number of Crew Rooms"
                onChange={(e) => {
                  this.setState({ number_crew_rooms: e.target.value });
                }}
              />

              <label htmlFor="crew_room_check_in">
                Crew Room Check-in Date
              </label>
              <input
                type="date"
                name="crew_room_check_in"
                placeholder="Crew Room Check-in"
                onChange={(e) => {
                  this.setState({ crew_room_check_in: e.target.value });
                }}
              />
              <label htmlFor="crew_room_check_out">
                Crew Room Check-out Date
              </label>
              <input
                type="date"
                name="crew_room_check_out"
                placeholder="Crew Room Check-out"
                onChange={(e) => {
                  this.setState({ crew_room_check_out: e.target.value });
                }}
              />

              <label htmlFor="crew_room_preference">Crew Room Preference</label>
              <select
                name="crew_room_preference"
                value={this.state.crew_room_preference}
                onChange={(e) => {
                  this.setState({ crew_room_preference: e.target.value });
                }}
              >
                <option value="King Bed">King Bed</option>
                <option value="Two Queens">Two Queens</option>
              </select>
              <p>
                Pilots selected for media flight will receive accommodations for
                Thursday night. Should you need a crew room(s), please contact
                Lynn Tyler at lynn@lakes101.org with your requested number of
                crew rooms and method of payment.
              </p>

              <p className="warning-red">
                <i>
                  Please plan to bring your own crew to the 2025 ABA, however,
                  if you are in NEED of local crew the event will attempt to
                  provide a maximum of one person for standard balloons.
                </i>
              </p>
              <label htmlFor="local_crew">Do you need local crew:</label>
              <select
                name="local_crew"
                value={this.state.local_crew}
                onChange={(e) => {
                  this.setState({ local_crew: e.target.value });
                }}
              >
                <option value="NO">NO</option>
                <option value="YES">YES</option>
              </select>
            </section>

            <section>
              <h1>Event Information</h1>
              <label htmlFor="friday_morning_event">
                Would you be interested in the Friday morning event? (Limited to
                15)
              </label>
              <select
                name="friday_morning_event"
                value={this.state.friday_morning_event}
                onChange={(e) => {
                  this.setState({ friday_morning_event: e.target.value });
                }}
              >
                <option
                  value=""
                  disabled
                  selected
                  className="select-placeholder"
                >
                  Select option..
                </option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>

              <label htmlFor="meal_party_size">
                Total number of people in your party for Friday/Saturday meals,
                if provided.
              </label>
              <input
                type="text"
                name="meal_party_size"
                placeholder="Meal Party Size"
                onChange={(e) => {
                  this.setState({ meal_party_size: e.target.value });
                }}
              />

              <label htmlFor="glow_participant">
                Do you plan to participate in the glow on Friday or Saturday
                evening? (required)
              </label>
              <select
                required
                name="glow_participant"
                value={this.state.glow_participant}
                onChange={(e) => {
                  this.setState({ glow_participant: e.target.value });
                }}
              >
                <option
                  value=""
                  disabled
                  selected
                  className="select-placeholder"
                >
                  Select option..
                </option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>

              <label htmlFor="awards_banquet_seats">
                Number of seat reservations required for the Sunday morning
                awards banquet:
              </label>
              <input
                type="text"
                name="awards_banquet_seats"
                placeholder="Number of Seats"
                onChange={(e) => {
                  this.setState({ awards_banquet_seats: e.target.value });
                }}
              />

              <label htmlFor="pilot_shirt_size">Pilot Shirt Size:</label>
              <select
                name="pilot_shirt_size"
                value={this.state.pilot_shirt_size}
                onChange={(e) => {
                  this.setState({ pilot_shirt_size: e.target.value });
                }}
              >
                <option
                  value=""
                  disabled
                  selected
                  className="select-placeholder"
                >
                  Select option..
                </option>
                <option value="Small">Small</option>
                <option value="Medium">Medium</option>
                <option value="Large">Large</option>
                <option value="X-Large">X-Large</option>
                <option value="2X-Large">2X-Large</option>
                <option value="3X-Large">3X-Large</option>
              </select>
            </section>

            <section>
              <h1>Participation Waiver</h1>
              <p>
                In consideration of being allowed to participate in the Angola
                Balloons Aloft, I hereby certify that I am aware of and agree
                with the responsibility legally transferred to me under the
                Federal Aviation Regulations as regards to my personal decision
                to fly my balloon, and any bodily injury or property damage
                resulting there from is solely my liability. I further agree
                that none of the flights are mandatory, that I will make flights
                at my option, and that my decision as pilot-in-command may
                affect my placement in this event if I choose not to fly. I
                further certify that I have in force a balloon insurance policy
                with minimum liability limits of $300,000 combined single limit,
                bodily injury and property damage with a limit not less than
                $100,000 per passenger.
              </p>
              <p>
                I agree that the organizers and sponsors of this event are
                providing me with the facilities and means for my participation
                in this event and in no way do they supersede the responsibility
                of the pilot-in- command as stated in the FAR's, or otherwise.
                Thus, I release the aforementioned parties from liability for
                their actions in relation to the Angola Balloons Aloft. It is my
                understanding that the pilot of each balloon is in full and
                complete charge and control of said balloon and is responsible
                for all decisions made concerning all things or persons in or
                connected with said balloon on the ground or in the air.
                Officials of said event may supply information, guidance, or
                data, but I understand that I act or rely on all such
                information at my own peril, and said officials assume no
                responsibility for the completeness or accuracy of such
                information.
              </p>
              <p>
                It is my understanding that the organizers may inspect all
                balloons entered in the event; but that such inspection is only
                for the purpose of determining whether the aircraft complies
                with all rules of eligibility of said competition. The approval
                of the balloon by said officials does not constitute a
                representation or warranty of any kind or character whatsoever
                concerning the mechanical condition of the aircraft, or whether
                or not it is airworthy. I hereby declare that I am participating
                in said competition of my own free volition and I accept the
                rules, regulations, and all appurtenances thereto.
              </p>
              <div>
                <label htmlFor="agree_to_terms" className="font--bold">
                  Agree to Terms:
                </label>
                <input
                  required={true}
                  type="checkbox"
                  name="agree_to_terms"
                  checked={this.state.agree_to_terms}
                  defaultValue={this.state.agree_to_terms}
                  onChange={(e) => {
                    this.setState({ agree_to_terms: e.target.checked });
                  }}
                />
              </div>
            </section>

            <section>
              <label htmlFor="comments">Additional Comments:</label>
              <textarea
                value={this.state.comments}
                name="comments"
                placeholder="Additional Comments..."
                rows={5}
                cols={5}
                onChange={(e) => {
                  this.setState({ comments: e.target.value });
                }}
              ></textarea>
              <section>
                <h1>Submit and Pay</h1>
                <p>
                  Corporate entry fee must be received with your registration
                  before it will be accepted and is the pilot's responsibility
                  to submit directly to Aviation Endeavors. Steuben County
                  Community Foundation will not accept direct payments.
                </p>
                <p>
                  You will be redirected to paypal to submit your registration
                  fee. (PayPal account not required)
                </p>
                {/* <Reaptcha
                                sitekey="6Lc885EUAAAAAErIcWoW9whp-DFzcitRm916iHl1"
                                onVerify={this.onCaptchaChange}
                            /> */}
                <label htmlFor="balloon_type">Balloon Type:</label>
                <select
                  name="balloon_type"
                  value={this.state.balloon_type}
                  onChange={(e) => {
                    this.setState({ balloon_type: e.target.value });
                  }}
                >
                  <option value="sport">Sport Balloon - $50 USD</option>
                  <option value="corporate">
                    Corporate Balloon - $650 USD
                  </option>
                  <option value="specialShape">Special Shape Balloon</option>
                </select>
                <h4>
                  <strong>
                    Please email the following documents to
                    aba.pilot.register@gmail.com
                  </strong>
                </h4>
                <ol>
                  <li>Airworthiness Certificate</li>
                  <li>Aircraft Registration</li>
                  <li>Balloon Image</li>
                </ol>
                <button type="submit">
                  {this.state.loading ? (
                    <span>
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Submitting...</span>
                      </Spinner>
                      <span className="sending-spinner">Sending...</span>
                    </span>
                  ) : (
                    "Submit"
                  )}
                </button>
              </section>
            </section>
          </form>
        </div>
      </div>
    );
  }
}

export default PilotRegistration;
