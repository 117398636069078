import React, { useRef } from "react";
import "./styles.css";
import HeroBox from "../HeroBox";
import { heroImages } from "../../resources/images";
import { pilots } from "../../resources/images";
import PilotCard from "./PilotCard";

const Pilots = () => {
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();
  return (
    <div>
      <HeroBox
        bgImage={heroImages.pilots}
        title={"Pilot Information"}
        // title={"Pilot Information Coming Soon!"}
        executeScroll={executeScroll}
      />

      <section className="top-section" ref={myRef}>
        <h1>2025 Pilots</h1>
        <div className="pilot-card-wrapper">
          {pilots.map((pilot) => {
            return <PilotCard key={pilot.balloonName} pilot={pilot} />;
          })}
        </div>
      </section>
    </div>
  );
};

export default Pilots;
