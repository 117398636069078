import React, { useRef } from "react";
import "./styles.css";
import HeroBox from "../HeroBox";
// import RoundImageGallery from '../RoundImageGallery';
import FullWidthGallery from "../FullWidthGallery";

/** Images **/
// import clawd_image from './images/clawd_the_crazy_neptuno.jpg';
// import cynthia_image from './images/cynthia_seal.jpg';
import { heroImages } from "../../resources/images";

/* Full width gallery images */
import { homeFullWidthGallery } from "../../resources/images";

const Home = () => {
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();
  return (
    <div>
      <HeroBox
        // bgImage={'https://res.cloudinary.com/dz1lfrxud/image/upload/v1620262322/hero-images/home/home_uqot47.jpg'}
        bgImage={heroImages.home}
        title={"Angola Balloons Aloft"}
        content={"July 11–12, 2025"}
        executeScroll={executeScroll}
      />

      <section className="top-section" ref={myRef}>
        <h1>2025 Events</h1>
        {/* <div className="content--nav">
          <ul>
            <li>Car Show</li>
            <li>Saturday Night Illumination</li>
            <li>Flights</li>
          </ul>
        </div> */}
      </section>
      <section>
        <h2>The balloons will fly at Angola Balloons Aloft</h2>
        <p>
          Angola Balloons Aloft 2025 set for July 11 and 12. Flights are
          scheduled for Friday evening, July 11; Saturday morning and event,
          July 12 and Sunday morning, July 13. In addition to the competition
          flights, the popular balloon illuminations are set for both Friday and
          Saturday evenings at Angola High School.
        </p>
        <p>
          We will have a kids fun zone and 18 food vendors. Look for the classic
          car show on Saturday as well. We continue to offer free admission and
          parking, thanks to the generosity of our sponsors. Please plan on
          bringing your lawn chairs and blankets so you can spread out and
          safely enjoy this wonderful community event!
        </p>
      </section>

      <section className="event-section">
        <h1>Helicopter Rides</h1>
        <div className="section-content-wrapper">
          <div className="section-content-left">
            <img
              className="round-image"
              src="https://i1.lensdump.com/i/gdGT5Q.jpg"
              alt="Helicopter Rides"
            />
            <p>
              Intrepid Helicopters will offer rides at Angola Balloons Aloft
              Friday, July 11 and Saturday, July 12. Rides are available Friday
              between 4 and 7 p.m. and Saturday between 10 a.m. and 7 p.m. at
              Angola High School. Helicopter rides are $50 per person.
            </p>
            {/* <p>
              Rides are $40 per person and last approximately five minutes. You will receive a $5 discount by registering ahead of time <a href="https://bit.ly/3wV0vPA" target="_blank" rel="noreferrer">HERE</a>.
            </p> */}
          </div>
          <div className="section-content-right">
            <p>
              You will not be able to sign up for a specific time frame, just
              for either Friday or Saturday. Rides are first come, first served
              so plan to arrive early. Lines will be long so please be patient.
            </p>
            <p>
              The helicopter carries three passengers and must have two
              passengers to take a flight. The weight limit per passenger is 275
              pounds and the total weight limit for all three passengers is 600
              pounds. If the total weight is getting close to 600 pounds or the
              individual weight is over 200 pounds, passengers will get on a
              scale that only the company can read. This is for everyone's
              safety and to abide by the limits of the aircraft. Intrepid prefer
              children older than 2 years old and all minors must be accompanied
              by a parent or guardian.
            </p>
          </div>
        </div>
      </section>

      <section className="event-section">
        <h1>Classic Car Show</h1>
        <div className="section-content-wrapper">
          <div className="section-content-left">
            <img
              className="round-image"
              src="https://i.lensdump.com/i/gdyrr3.jpg"
              alt="Classic Car Show"
            />
            <p>
              The Angola Balloons Aloft classic car show includes a 60-mile
              cruise through Steuben County.
            </p>
            <p>
              There's no cost to join the show or cruise and no pre-registration
              is required. Free t-shirts will be given to the first 150 vehicle
              owners.
            </p>
          </div>
          <div className="section-content-right">
            <p>
              Meet at Angola High School between 8:30 and 10 a.m. Saturday, July
              12 for the car show. The cruise will leave the school just after
              10 a.m. and go through several communities including Pleasant
              Lake, Hamilton, Orland and Fremont, where they will make brief
              stops. The cars will return to the high school at approximately
              3:15 p.m. Classic car owners are then welcome to stay for the
              evening events while enjoying a prime parking spot at the high
              school.
            </p>
            <p>
              E-mail{" "}
              <a href="mailto:barlettgary65@gmail.com">
                barlettgary65@gmail.com
              </a>{" "}
              with any questions.
            </p>
          </div>
        </div>
      </section>

      <section className="event-section">
        <h1>Food Vendors</h1>

        <div className="section-content-wrapper">
          <div className="section-content-left">
            <img
              className="round-image"
              src="https://i.lensdump.com/i/gdGRnM.jpg"
              alt="Food Vendors"
            />
            <p>
              Our food vendors will have a wide variety of delicious treats.
            </p>
          </div>

          <div className="section-content-right">
            <ul className="food-list">
              <li>Hamburgers</li>
              <li>Hot Dogs</li>
              <li>Flavored Ice/ Snow Cones</li>
              <li>Mini Donuts</li>
              <li>Soft Pretzels</li>
              <li>Kettle Corn</li>
              <li>Elephant Ears</li>
              <li>Cotton Candy</li>
              <li>Walking Tacos</li>
              <li>Nachos</li>
              <li>Homemade Ice Cream</li>
              <li>Hand Dipped Ice Cream</li>
              <li>Lemonade</li>
              <li>Iced Tea</li>
              <li>Soft Drinks</li>
              <li>Coffee</li>
              <li>Iced Coffee</li>
            </ul>
          </div>
        </div>
      </section>

      <FullWidthGallery images={homeFullWidthGallery} />

      <section className="contact-info">
        <h1>Contact Info</h1>
        <div>
          <ul className="contact-info-list">
            <li>Angola Balloons Aloft</li>
            <li>(260) 665-5386</li>
            <li>AngolaBalloonsAloft@gmail.com</li>
            <li>For local lodging information, visit www.lakes101.org</li>
          </ul>
        </div>
      </section>

      <section className="event-rules">
        <div>
          <img src="https://i3.lensdump.com/i/tpSZyr.png" alt="event rules" />
          <p>No Smoking. No Alcohol. No Pets. No Drones.</p>
        </div>
      </section>
    </div>
  );
};

export default Home;
