export const schedule = {
  friday: {
    date: "Friday, July 11",
    events: [
      {
        time: "7AM",
        name: "Media Flight",
      },
      {
        time: "4PM - 7PM",
        name: "Paid Helicopter Rides",
      },
      {
        time: "4PM - 9PM",
        name: "Kid's Fun Zone",
      },
      {
        time: "4PM - 9PM",
        name: "Antique Tractor Show",
      },
      {
        time: "4PM - 9PM",
        name: "Food Vendors",
      },
      {
        time: "4PM - 9PM",
        name: "Free Archery Tag",
      },
      {
        time: "6PM",
        name: "Opening Ceremonies",
      },
      {
        time: "7PM",
        name: "Fun Flight and Special Shapes",
      },
      {
        time: "Twilight",
        name: "Hot Air Balloon Illumination",
      },
    ],
  },
  saturday: {
    date: "Saturday, July 12",
    events: [
      {
        time: "7AM",
        name: "Competition Flight",
      },
      {
        time: "7AM - 10AM",
        name: "Pancake Breakfast by Angola Band Boosters",
      },
      {
        time: "8:30AM - 10AM",
        name: "Classic Car Show - Leaves for Cruise-in at 10AM",
      },
      {
        time: "8:30AM - 10AM",
        name: "Music by Retro Radio",
      },
      {
        time: "10AM - 7PM",
        name: "Paid Helicopter Rides",
      },
      {
        time: "11AM - 4PM",
        name: "Antique Tractor Show",
      },
      {
        time: "11AM",
        name: "Juggling and Stunt show",
      },
      {
        time: "Noon",
        name: "Sky FX Kite Show",
      },
      {
        time: "Noon - 9PM",
        name: "Food Vendors",
      },
      {
        time: "Noon - 9PM",
        name: "Kid's Fun Zone",
      },
      {
        time: "Noon - 9PM",
        name: "Free Archery Tag",
      },
      {
        time: "1PM",
        name: "Strongman Jon Bruney",
      },
      {
        time: "2PM",
        name: "Sky FX Kite Show",
      },
      {
        time: "2PM - 4PM",
        name: "Soarin' Hawk Raptor Rehab Demo",
      },
      {
        time: "3PM",
        name: "Juggling and Stunt Show",
      },
      {
        time: "3:30PM",
        name: "Classic Car Show Returns & Static Car Display",
      },
      {
        time: "4PM",
        name: "Sky FX Kite Show",
      },
      {
        time: "4:30PM - 5:30PM",
        name: "Meet the Balloon Pilots",
      },
      {
        time: "7PM",
        name: "Competition Flight",
      },
      {
        time: "Twilight",
        name: "Hot Air Balloon Illumination",
      },
    ],
  },
  sunday: {
    date: "Sunday July 14",
    events: [
      {
        time: "7AM",
        name: "Competition Flight",
      },
    ],
  },
};
