import React, { useRef } from "react";
import "./styles.css";
import HeroBox from "../HeroBox";
import { heroImages } from "../../resources/images";
import { sponsors } from "../../resources/sponsors.js";

const Sponsors = () => {
  const renderSponsors = (arr) => {
    let url;
    let inner_content;
    let content;
    return arr.map((sponsor) => {
      if (sponsor.url) {
        url = sponsor.url;
      }
      if (sponsor.image) {
        inner_content = (
          <li key={sponsor.name} className="sponsors-item">
            <img src={sponsor.image} alt={sponsor.name} />
          </li>
        );
      } else {
        inner_content = (
          <li key={sponsor.name} className="sponsors-item">
            <span className="l-text">{sponsor.name}</span>
          </li>
        );
      }
      if (url) {
        content = (
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            className="sponsors-link"
          >
            {inner_content}
          </a>
        );
      } else {
        content = inner_content;
      }
      return content;
    });
  };
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();
  return (
    <div>
      <HeroBox
        bgImage={heroImages.sponsors}
        title={"2025 Sponsors"}
        content={"Learn about our sponsors"}
        // content={'Info Coming Soon!'}
        executeScroll={executeScroll}
      />
      <section className="sponsors-wrapper" ref={myRef}>
        <section>
          <h1 className="sponsors-large-heading">Gold Sponsors</h1>
          <ul className="sponsors-list">{renderSponsors(sponsors.gold)}</ul>
        </section>
        {/* <section>
          <h1 className="sponsors-large-heading">Premier Sponsors</h1>
          <ul className="sponsors-list">
            {renderSponsors(sponsors.premier)}
          </ul>
        </section> */}
        <section>
          <h1 className="sponsors-large-heading">VIP Sponsors</h1>
          <ul className="sponsors-list">{renderSponsors(sponsors.vip)}</ul>
        </section>
        {/* <section>
          <h1 className="sponsors-large-heading">Illumination Sponsor</h1>
          <ul className="sponsors-list">
            {renderSponsors(sponsors.illumination)}
          </ul>
        </section> */}
        <section>
          <h1 className="sponsors-large-heading">Corporate Sponsors</h1>
          <ul className="sponsors-list">
            {renderSponsors(sponsors.corporate)}
          </ul>
        </section>
        <section>
          <h1 className="sponsors-large-heading">Balloon Sponsors</h1>
          <ul className="sponsors-list">{renderSponsors(sponsors.balloon)}</ul>
        </section>
        <div className="specialty-container">
          <section>
            <h1 className="sponsors-large-heading">Pole Grab Sponsor</h1>
            <ul className="sponsors-list">
              {renderSponsors(sponsors.polegrab)}
            </ul>
          </section>
          <section>
            <h1 className="sponsors-large-heading">Archery Sponsor</h1>
            <ul className="sponsors-list">
              {renderSponsors(sponsors.archery)}
            </ul>
          </section>
        </div>
        <section>
          <h1 className="sponsors-medium-heading">Kid's Fun Zone Sponsors</h1>
          <ul className="sponsors-list">
            {renderSponsors(sponsors.kidsfunzone)}
          </ul>
        </section>
        <section>
          <h1 className="sponsors-medium-heading">T-Shirt Sponsors</h1>
          <ul className="sponsors-list">{renderSponsors(sponsors.tshirt)}</ul>
        </section>
        <section>
          <h1 className="sponsors-small-heading">Contributing Sponsors</h1>
          <ul className="sponsors-list">
            {sponsors.contributing.map((sponsor) => (
              <li key={sponsor} className="sponsors-item">
                {sponsor}
              </li>
            ))}
          </ul>
        </section>
      </section>
    </div>
  );
};

export default Sponsors;
