import React, { useRef } from "react";
import "./styles.css";
import HeroBox from "../HeroBox";
import { heroImages } from "../../resources/images";
import { schedule } from "../../resources/schedule";

const Schedule = () => {
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();

  const renderSchedule = (date, events) => {
    return (
      <section className="day-wrapper">
        <h2 className="event-day">{date}</h2>
        <ul className="event-info-wrapper">
          {events.map((event, index) => {
            return (
              <li className="event-info" key={index}>
                <span className="event-time">{event.time}</span>
                <p className="event-info-text">{event.name}</p>
              </li>
            );
          })}
        </ul>
      </section>
    );
  };

  return (
    <div>
      <HeroBox
        bgImage={heroImages.schedule}
        title={"2025 Schedule!"}
        content={"The 2025 schedule coming soon!"}
        executeScroll={executeScroll}
      />

      <section className="top-section" ref={myRef}>
        <h1>Schedule of Events</h1>
        <h2>July 11-12, 2025</h2>
        <h3>Angola High School</h3>
        <h3>350 S. John McBride Ave.</h3>
        <h3>Angola, IN 46703</h3>
      </section>

      <section className="event-rules">
        <div>
          <img src="https://i3.lensdump.com/i/tpSZyr.png" alt="event rules" />
          <p>No Smoking. No Alcohol. No Pets. No Drones.</p>
        </div>
      </section>

      <section className="schedule-wrapper">
        {renderSchedule(schedule.friday.date, schedule.friday.events)}
        {renderSchedule(schedule.saturday.date, schedule.saturday.events)}
        {renderSchedule(schedule.sunday.date, schedule.sunday.events)}
        <p className="schedule-disclaimer">
          Times are approximate and schedules may change without notice. All
          events during Angola Balloons Aloft are dependent on prevailing
          weather conditions. The safety of our pilots, volunteers and
          spectators is our primary concern.
        </p>
      </section>
    </div>
  );
};

export default Schedule;
