import React, { useRef } from "react";
import "./styles.css";
import HeroBox from "../HeroBox";
import { heroImages } from "../../resources/images";

const specialShapes = [
  {
    name: "Ellie the Elephant",
    image: "https://a.l3n.co/i/phAWvo.webp",
    alt: "Special Shape Balloon",
    sponsorName: "",
    sponsorLink: "",
  },
  {
    name: "Yellow Bird and Puddy Cat",
    image: "https://b.l3n.co/i/phAZXC.webp",
    alt: "Special Shape Balloon",
    sponsorName: "",
    sponsorLink: "",
  },
  {
    name: "Tom Cat and Terry Mouse",
    image: "https://a.l3n.co/i/phA1NT.webp",
    alt: "Special Shape Balloon",
    sponsorName: "",
    sponsorLink: "",
  },
  {
    name: "Smiley",
    image: "https://c.l3n.co/i/phA0k2.webp",
    alt: "Special Shape Balloon",
    sponsorName: "",
    sponsorLink: "",
  },
  {
    name: "Cynthia Seal",
    // image: "https://i.lensdump.com/i/TxVbfH.png",
    image: "https://c.l3n.co/i/ph8cvk.webp",
    alt: "Special Shape Balloon",
    sponsorName: "Denny Zent",
    sponsorLink: "https://www.facebook.com/dennyzent/",
  },
];

const SpecialShapes = () => {
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();
  return (
    <div>
      <HeroBox
        bgImage={heroImages.special_shapes}
        title={"Special Shapes"}
        content={"Welcome Special Shapes 2025"}
        executeScroll={executeScroll}
      />

      <section
        className="section-description partners js--section-intro"
        id="partner-top"
        ref={myRef}
      >
        <h1>Special Shapes 2025</h1>
        <div className="special-shape-balloon-gallery">
          {specialShapes.map((shape) => {
            return (
              <div className="special-shape-balloon-wrapper">
                <h2>{shape.name}</h2>
                <img
                  src={shape.image}
                  alt={shape.alt}
                  className="special-shape-balloon"
                />
                {/* <h3 className="special-shape--sponsor">
              <span className="italic">Sponsor:</span>
              <br />
              <a
                href={shape.sponsorLink}
                target="_blank"
                rel="noreferrer"
              >
                {shape.sponsorName}
              </a>
            </h3> */}
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default SpecialShapes;
